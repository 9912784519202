<template>
	<span
		class="copy-btn fa fa-fw fa-copy"
		title="Click to copy"
		@click="onClick"
	></span>
</template>

<style lang="less">
.copy-btn {
	cursor: pointer;
}
</style>

<script>
export default {
	props: ['text'],
	methods: {
		onClick() {
			this.copy(this.text);
		}
	}
}
</script>
