<template>
	<div class="auth-box">
		<div class="auth-text">
			<template v-if="mode === 'forgot'">
				{{ ii('ENTER_EMAIL_GET_RESTORE_INSTRUCTIONS') }}
			</template>
		</div>
		<form class="auth-form" @submit.prevent="onSubmit">
			<div v-if="mode === 'login'">
				<div class="auth-input-row">
					<s-text-field
						v-model="email"
						prepend-icon="envelope-o"
						:placeholder="ii('E_MAIL')"
						name="email"
						autofocus="1"
						:disabled="isLoading"
						:error="logEmailError"
					></s-text-field>
				</div>

				<div class="auth-input-row">
					<s-text-field
						v-model="password"
						type="password"
						prepend-icon="lock"
						:placeholder="ii('PASSWORD')"
						name="password"
						:disabled="isLoading"
						:error="logPassError"
					></s-text-field>
				</div>

				<div class="auth-btn-row text-center">
					<s-btn
						icon="sign-in"
						type="submit"
						:loading="isLoading"
					>
						{{ ii('TO_LOGIN') }}
					</s-btn>
				</div>
				<div class="auth-forgot-row">
					<span class="link" @click="setMode('forgot')">{{ ii('FORGOT_PASSWORD_QST') }}</span>
				</div>
				<soc-login @close="app.onAuth()"></soc-login>
				<div class="auth-alt-option">
					{{ ii('HAVE_NO_ACCOUNT_QST') }} <span class="link" @click="setMode('register')">{{ ii('TO_REGISTER') }}</span>
				</div>
			</div>

			<div v-else-if="mode === 'register'">
				<div class="auth-input-row">
					<s-text-field
						v-model="regEmail"
						prepend-icon="envelope-o"
						:placeholder="ii('E_MAIL')"
						name="email"
						autofocus="1"
						:disabled="isLoading"
						:error="ii(regErrors.email || regErrors.general)"
					></s-text-field>
				</div>
				<div class="auth-input-row">
					<s-text-field
						v-model="regUsername"
						prepend-icon="user-circle"
						:placeholder="ii('NAME')"
						name="username"
						:disabled="isLoading"
						:error="ii(regErrors.name)"
					></s-text-field>
				</div>
				<div class="auth-input-row">
					<s-text-field
						v-model="regPassword"
						type="password"
						prepend-icon="lock"
						:placeholder="ii('PASSWORD')"
						name="password"
						:disabled="isLoading"
						:error="ii(regErrors.password)"
					></s-text-field>
				</div>
				<div class="auth-input-row">
					<s-text-field
						v-model="regConfirm"
						type="password"
						prepend-icon="lock"
						:placeholder="ii('CONFIRM_PASSWORD')"
						name="password"
						:disabled="isLoading"
						:error="ii(regErrors.confirm)"
					></s-text-field>
				</div>
				<div class="auth-btn-row text-center">
					<s-btn
						icon="plus"
						type="submit"
						:loading="isLoading"
					>
						{{ ii('CREATE_ACCOUNT') }}
					</s-btn>
				</div>
				<soc-login @close="app.onAuth()"></soc-login>
				<div class="auth-alt-option">
					{{ ii('ALREADY_HAVE_ACCOUNT_QST') }} <span class="link" @click="setMode('login')">{{ ii('TO_LOGIN') }}</span>
				</div>
			</div>

			<div v-else-if="mode === 'forgot'">
				<div class="auth-input-row">
					<s-text-field
						v-model="remindEmail"
						prepend-icon="envelope-o"
						:placeholder="ii('E_MAIL')"
						name="email"
						:disabled="isLoading"
					></s-text-field>
				</div>
				<div class="auth-btn-row text-center">
					<s-btn
						icon="send"
						type="submit"
						:loading="isLoading"
					>
						{{ ii('SEND') }}
					</s-btn>
				</div>
				<div class="auth-back-row">
					<span class="link" @click="setMode(prevMode)">{{ ii('BACK') }}</span>
				</div>
			</div>
		</form>
	</div>
</template>

<script>
	import SocLogin from './SocLogin.vue';

	export default {
		props: ['value'],
		components: {
			'soc-login': SocLogin
		},
		data() {
			return {
				mode: 'login',
				prevMode: null,

				email: '',
				password: '',

				regUsername: '',
				regEmail: '',
				regPassword: '',
				regConfirm: '',

				remindEmail: '',

				isLoading: false,
				loginErrors: [],

				logEmailError: null,
				logPassError: null,

				regErrors: {
					general: null,
					email: null,
					name: null,
					password: null,
					confirm: null,
				},

				defaultLoginError: 'Wrong login or password',
				defaultRegisterError: 'An error occurred'
			};
		},
		methods: {
			setMode(mode) {
				this.loginErrors = [];
				this.prevMode = this.mode;
				this.mode = mode;
				this.$emit('modechanged', {mode});
			},
			onSubmit() {
				if (this.mode === 'login') {
					this.tryLogin();
				} else if (this.mode === 'register') {
					this.tryRegister();
				} else if (this.mode === 'forgot') {
					this.alert('Not working, yet');
				}
			},
			async tryLogin() {
				this.logPassError = this.logEmailError = null;
				if (!this.email) {
					this.logEmailError = this.ii('E_MAIL_IS_REQUIRED');
				}
				if (!this.password) {
					this.logPassError = this.ii('PASSWORD_IS_REQUIRED');
				}
				if (this.logEmailError || this.logPassError) return;

				this.isLoading = true;
				let res = await this.post('Auth.login', this.email, this.password);
				this.isLoading = false;

				if (res.err) {
					this.logEmailError = this.ii('WRONG_LOGIN_OR_PASSWORD');
				} else {
					this.app.onAuth({type: 'login'});
				}
			},
			async tryRegister() {
				this.regErrors = {};
				if (!this.regEmail || !this.regUsername || !this.regPassword || !this.regConfirm) {
					this.regErrors.general = 'PLEASE_FILL_ALL_THE_FIELDS';
					return;
				}

				this.isLoading = true;

				let res = await this.post('Auth.register', {
					email: this.regEmail,
					name: this.regUsername,
					password: this.regPassword,
					confirm: this.regConfirm
				});

				this.isLoading = false;

				if (res.err) {
					this.regErrors[res.err.field] = res.err.msg;
				} else {
					this.app.onAuth({type: 'register'});
				}
			}
		}
	}
</script>

<style lang="less">
@import "../styles/helpers";

.auth-box {
	padding: 0 0 10px;
	background: #fff;

	.auth-form {
		@formWidth: 300px;

		margin: 0 auto 0;
		width: @formWidth + 2px;

		.auth-input-row {
			margin: 3px 0;
			height: 34px;
		}

		.auth-btn-row {
			padding: 5px 0 0;
		}
	}
	.auth-error-box {
		padding: 5px 0 10px;
		text-align: center;

		.auth-err {
			font-size: 13px;
			color: #f00;
		}
	}
	.auth-3party {
		padding: 20px 0 0 0;
		text-align: center;

		span.link {
			margin-left: 5px;

			&:hover {
				text-decoration: none !important;

				span {
					text-decoration: underline;
				}
			}
		}
	}
	.auth-forgot-row {
		padding: 7px 0 0;
		text-align: center;
	}
	.auth-alt-option {
		padding: 20px 0 0;
		text-align: center;
	}
	.auth-text {
		padding: 0 30px 20px;
	}
	.auth-back-row {
		padding: 30px 0 0;
		text-align: center;
	}
}
</style>
