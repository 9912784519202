import Vue from 'vue';
import Router from 'vue-router';
import methods from './mixin/methods';
import utils from "./mixin/utils";

Vue.use(Router);

const routes = [
	{
		path: '/',
		name: 'home',
		component: () => import(/* webpackChunkName: "home" */ './views/Home.vue')
	},
	{
		path: '/terms-of-service',
		name: 'terms',
		component: () => import(/* webpackChunkName: "terms" */ './views/Terms.vue')
	},
	{
		path: '/privacy-policy',
		name: 'privacy',
		component: () => import(/* webpackChunkName: "privacy" */ './views/Privacy.vue')
	},
	{
		path: '/timestamp-converter',
		name: 'timestamp',
		component: () => import(/* webpackChunkName: "timestamp" */ './views/Timestamp.vue')
	},
	{
		path: '/md5-hash-generator',
		name: 'md5',
		component: () => import(/* webpackChunkName: "md5" */ './views/MD5.vue')
	},
	{
		path: '/ip-geolocation',
		name: 'geoip',
		component: () => import(/* webpackChunkName: "geoip" */ './views/GeoIP.vue')
	},
	{
		path: '/http-debugger',
		name: 'httpdebugger',
		component: () => import(/* webpackChunkName: "httpdebugger" */ './views/HttpResponseDebugger.vue')
	},
	{
		path: '/url-parser',
		name: 'urlParser',
		component: () => import(/* webpackChunkName: "urlparser" */ './views/UrlParser.vue')
	},
	{
		path: '/color-converter',
		name: 'colorConverter',
		component: () => import(/* webpackChunkName: "colorconverter" */ './views/ColorConverter.vue')
	},
	{
		path: '/base64-encoder',
		name: 'baseEncoder',
		component: () => import(/* webpackChunkName: "baseencoder" */ './views/BaseEncoder.vue')
	},
	{
		path: '/json-beautifier',
		name: 'jsonBeautifier',
		component: () => import(/* webpackChunkName: "jsonbeautifier" */ './views/JsonBeautifier.vue')
	},
	{
		path: '/whois',
		name: 'whoisInfo',
		component: () => import(/* webpackChunkName: "whois" */ './views/Whois.vue')
	},
	{
		path: '/whois/raw',
		name: 'whoisRaw',
		component: () => import(/* webpackChunkName: "whois" */ './views/Whois.vue')
	},
	{
		path: '/dns',
		name: 'dns',
		component: () => import(/* webpackChunkName: "dns" */ './views/DNS.vue')
	},
	{
		path: '/ssl',
		name: 'sslInfo',
		component: () => import(/* webpackChunkName: "ssl" */ './views/SSL.vue')
	},
	{
		path: '/sitemap-parser',
		name: 'sitemapParser',
		component: () => import(/* webpackChunkName: "settings" */ './views/SitemapParser.vue')
	},
	{
		path: '/sitemap-parser/urls',
		name: 'sitemapParserUrls',
		component: () => import(/* webpackChunkName: "settings" */ './views/SitemapParser.vue')
	},
	{
		path: '/sitemap-parser/raw',
		name: 'sitemapParserRaw',
		component: () => import(/* webpackChunkName: "settings" */ './views/SitemapParser.vue')
	},
	{
		path: '/punycode',
		name: 'punycode',
		component: () => import(/* webpackChunkName: "punycode" */ './views/Punycode.vue')
	},
	{
		path: '/tabs-to-spaces',
		name: 'tabsToSpaces',
		component: () => import(/* webpackChunkName: "tabstospaces" */ './views/TabsToSpaces.vue')
	},
	{
		path: '/useragent',
		name: 'userAgentParser',
		component: () => import(/* webpackChunkName: "useragent" */ './views/UserAgent.vue')
	},
	{
		path: '/number-converter',
		name: 'numberСonverter',
		component: () => import(/* webpackChunkName: "numberconverter" */ './views/NumberConverter.vue')
	},
	{
		path: '/metatag',
		name: 'metaTagParser',
		component: () => import(/* webpackChunkName: "metatagparser" */ './views/MetaTagParser.vue')
	},
	{
		path: '/password',
		name: 'passwordGenerator',
		component: () => import(/* webpackChunkName: "settings" */ './views/Password.vue')
	},
	{
		path: '/settings*',
		name: 'settings',
		component: () => import(/* webpackChunkName: "settings" */ './views/Settings.vue')
	},
	{
		path: '*',
		component: () => import(/* webpackChunkName: "notfound" */ './views/NotFound.vue'),
	}
];

routes.forEach(r => {
	r.path = '/:lang' + (r.path.indexOf('/') !== 0 ? '/' : '') + r.path;
});

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	if (!/\/[a-z]{2}(\/|$)/.test(to.path)) {
		let lang = utils.getCookie('lang');
		if (!lang && navigator.language) {
			lang = navigator.language.slice(0, 2);
		}
		if (!lang) {
			lang = 'en';
		}

		let path = '/' + lang + to.fullPath;
		next({ path });
	} else if (to.params && to.params.lang === 'ru') {
		let path = to.fullPath.replace(/^\/ru\//, '/en/');
		next({ path });
	} else {
		next();
	}
});

router.afterEach((to) => {
	methods.hit('visit', to.name);
});

export default router
