<template>
	<div>
		<div class="sidebar-overlay" @click="app.toggleSidebar('overlay')"></div>
		<div class="sidebar">
			<template v-if="shouldShowContent">
				<div v-for="item in app.menuItems" class="sidebar-item">
					<app-link
						v-if="item"
						:to="item.url"
						:class="item.url === '/' + app.page ? 'current' : ''"
					>
						<i :class="'fa fa-fw fa-' + item.icon"></i>
						{{ ii(item.text) }}
					</app-link>
					<div v-else class="sidebar-sep"></div>
				</div>
				<div class="sidebar-sep"></div>
				<div class="sidebar-item">
					<div class="setting-item">
						<i class="fa fa-fw fa-photo"></i>
						{{ ii('THEME') }}:
						<s-drop-panel ref="themePicker" class="theme-picker">
							<strong>{{ app.getThemeText() }}</strong>
							<div slot="box" class="theme-picker-box">
								<div v-for="theme in app.themes" @click="onThemeClick(theme)">{{ theme.text }}</div>
							</div>
						</s-drop-panel>
					</div>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	computed: {
		shouldShowContent() {
			return this.app.menuItems.length && this.app.loadedLangs.length;
		}
	},
	methods: {
	    onThemeClick(theme) {
            this.app.setTheme(theme.key);
            this.$refs.themePicker.close();
		}
	}
}
</script>