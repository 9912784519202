<template>
	<div class="drop-panel" :class="classMap">
		<div class="drop-panel-clickable" @click="toggle">
			<slot></slot>
		</div>
		<transition name="drop-anim">
			<div v-if="active" class="drop-panel-box" ref="box">
				<div class="drop-panel-corner"></div>
				<div class="drop-panel-box-inner">
					<slot name="box"></slot>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
export default {
	props: ['position'],
	data() {
		return {
			active: false
		};
	},
	computed: {
		classMap() {
			return {
				'drop-panel-right': (this.position === 'right')
			};
		}
	},
	methods: {
		toggle() {
			this.active = !this.active;

			if (this.active) {
				setTimeout(() => {
					document.addEventListener('click', this.onDocClick)
				}, 100);
			} else {
				document.removeEventListener('click', this.onDocClick);
			}
		},
		close() {
			if (!this.active) return;

			this.toggle();
		},
		onDocClick(e) {
			let isInside = this.$refs.box.contains(e.target);
			if (!isInside && this.active) {
				this.toggle();
			}
		}
	},
	destroyed() {
		document.removeEventListener('click', this.onDocClick);
	}
}
</script>

<style lang="less">
.drop-panel {
	display: inline-block;
	position: relative;

	.drop-anim-enter-active, .drop-anim-leave-active {

	}
	.drop-anim-enter, .drop-anim-leave-to {

	}

	.drop-panel-clickable {
		display: inline-block;
		width: 100%;
	}

	.drop-panel-box {
		position: absolute;
		left: 0;
		margin-top: 5px;
		border: 1px solid #000;
		line-height: normal;
	}

	.drop-panel-box-inner {
		background: #fff;
		position: relative;
	}

	.drop-panel-corner {
		position: absolute;
		left: 20px;
		top: -5px;
		width: 14px;
		height: 14px;
		background: #fff;
		border: 1px solid #000;
		transform: rotate(45deg);
	}

	&.drop-panel-right {
		.drop-panel-box {
			right: 0;
			left: auto;
		}

		.drop-panel-corner {
			right: 20px;
			left: auto;
		}
	}
}
</style>
