import { render, staticRenderFns } from "./FeedbackForm.vue?vue&type=template&id=ce37f6c0&"
import script from "./FeedbackForm.vue?vue&type=script&lang=js&"
export * from "./FeedbackForm.vue?vue&type=script&lang=js&"
import style0 from "./FeedbackForm.vue?vue&type=style&index=0&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports