<template>
	<span class="tbl-sort link" @click="sort">
		<slot></slot>
		<i v-if="state" :class="'sort-icon zmdi zmdi-triangle-' + state"></i>
	</span>
</template>

<script>
export default {
	props: ['value', 'sort-key', 'reverse'],
	computed: {
		state() {
			if (!this.value) return;

			let state = 'up';
			let key = this.value;
			if (key.charAt(0) === '-') {
				key = key.slice(1);
				state = 'down';
			}
			if (key !== this.sortKey) return null;

			return state;
		}
	},
	methods: {
		sort() {
			let val = this.sortKey;
			if (this.value === val || this.reverse && this.value !== '-' + val) {
				val = '-' + val;
			}
			this.$emit('input', val);
		}
	}
}
</script>

<style lang="less">
.tbl-sort {
	.sort-icon {
		font-size: 11px;
		margin-left: 3px;
	}
}
</style>
