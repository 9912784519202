<template>
	<div>
		<div class="header">
			<div class="header-left">
				<div class="burger" @click="app.toggleSidebar('header')">
					<span v-for="i in 3"></span>
				</div>
				<app-link to="/" class="logo-link">{{ ii('SEMALT_TOOLS') }}</app-link>
			</div>
			<div class="header-right">
				<s-drop-panel position="right" ref="langPicker">
					<span class="header-menu-item">
						<i v-if="app.langLoading" class="fa fa-spinner spin-fast"></i>
						<s-flag v-else :cc="app.getLangGeo()"></s-flag>
					</span>
					<div class="header-lang-picker" slot="box">
						<app-link
							v-for="lang in app.languages"
							:to="app.getLangUrl(lang.key)"
							:key="lang.key"
							@click.native="$refs.langPicker.close(); app.chooseLang(lang.key)"
						>
							<s-flag :cc="lang.geo" class="lang-flag"></s-flag>
							<span>{{ lang.text }}</span>
						</app-link>
					</div>
				</s-drop-panel>

				<s-drop-panel v-if="app.user" position="right" ref="menuPanel">
					<span class="header-menu-item">
						<img v-if="app.user.profilePic" src="/api/profile-pic" class="header-profile-pic">
						<template v-else>{{ app.user.name }}</template>
						<i class="fa fa-fw fa-chevron-down pointer"></i>
					</span>
					<div class="header-settings" slot="box">
						<div class="header-settings-name">
							{{ app.user.name }}
						</div>
						<div class="header-settings-email">
							{{ app.user.email }}
						</div>
						<div class="header-settings-sep"></div>
						<app-link
							to="/settings"
							class="header-settings-link"
							@click.native="$refs.menuPanel.close"
						>
							<i class="fa fa-fw fa-cog"></i>
							<span>{{ ii('SETTINGS') }}</span>
						</app-link>
						<span class="header-settings-link" @click="logout">
							<i v-if="isLoggingOut" class="fa fa-fw fa-spinner spin-fast"></i>
							<i v-else class="fa fa-fw fa-sign-out"></i>
							<span>{{ ii('SIGN_OUT') }}</span>
						</span>
					</div>
				</s-drop-panel>
				<span v-else-if="app.user === null" class="header-menu-item" @click="app.showAuthPopup">
					{{ ii('SIGN_IN') }}
					<i class="fa fa-fw fa-sign-in"></i>
				</span>
			</div>
		</div>
		<div class="header-padding"></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			isLoggingOut: false
		};
	},
	methods: {
		async logout() {
			this.isLoggingOut = true;
			await this.app.logout();
			this.isLoggingOut = false;
		}
	}
}
</script>