<template>
	<router-link :to="destination"><slot></slot></router-link>
</template>

<script>
export default {
	props: ['to'],
	computed: {
		destination() {
			if (typeof this.to !== 'string') return this.to;
			if (/^\/[a-z]{2}(\/|\?|$)/.test(this.to)) return this.to;
			return '/' + this.app.lang + this.to;
		}
	}
}
</script>