<template>
	<div class="text-area" :class="classMap">
		<div v-if="error" class="error-msg">{{ error }}</div>
		<div v-if="prependIcon" class="prepend-icon" @click="focus">
			<i :class="'fa fa-fw fa-' + prependIcon"></i>
		</div>
		<textarea
			ref="tf"
			:value="value"
			:placeholder="placeholder"
			:name="name"
			:disabled="disabled"
			:readonly="readonly"
			@focus="onFocus"
			@blur="onBlur"
			@input="$emit('input', $event.target.value)"
			@change="$emit('change', $event)"
		></textarea>
		<i v-if="error" class="error-icon fa fa-exclamation-triangle"></i>
		<i v-if="loading" class="loading-icon fa fa-spinner spin-fast"></i>
	</div>
</template>

<script>
export default {
	props: [
		'value',
		'type',
		'prepend-icon',
		'placeholder',
		'name',
		'disabled',
		'readonly',
		'error',
		'loading',
		'autofocus'
	],
	data() {
		return {
			focused: false
		}
	},
	computed: {
		inputType() {
			return this.type || 'text';
		},
		classMap() {
			return {
				'text-area-left-icon': this.prependIcon,
				'text-area-focused': this.focused,
				'text-area-disabled': this.disabled,
				'text-area-error': this.error,
				'text-area-loading': this.loading
			};
		}
	},
	methods: {
		onFocus() {
			this.focused = true;
		},
		onBlur() {
			this.focused = false;
		},
		focus() {
			this.$refs.tf.focus();
		}
	},
	mounted() {
		if (this.autofocus) {
			this.focus();
		}
	}
}
</script>

<style lang="less">
@import "../styles/helpers";

.text-area {
	@tfHeight: 150px;
	@iconWidth: 30px;
	@padding: 8px;

	display: inline-block;
	position: relative;
	width: 100%;
	border: 1px solid #aaa;
	background: #fff;

	&.text-area-focused {

	}

	&.text-area-disabled textarea {
		background: #f3f3f3;
	}

	&.text-area-error {
		border: 1px solid rgba(255, 0, 0, 0.4);
	}

	textarea {
		padding-left: @padding;
		padding-right: @padding;
		width: 100%;
		min-width: 100%;
		max-width: 100%;
		height: @tfHeight;
		font-size: 13px;
		font-family: inherit;
		border: none;
		outline: none;
		background: transparent;
	}

	&.text-area-left-icon textarea {
		padding-left: @iconWidth + @padding;
	}

	.prepend-icon {
		position: absolute;
		left: 0;
		border-right: 1px solid #bfcbd9;
		width: @iconWidth;
		text-align: center;
		background: #fbfdff;
		color: #97a8be;
		font-size: 13px;
	}

	.error-msg {
		position: absolute;
		bottom: 0;
		right: 20px;
		color: #f00;
		z-index: 1;
		font-size: 13px;
	}

	.error-icon {
		position: absolute;
		bottom: 2px;
		right: 5px;
		font-size: 11px;
		color: #f00;
	}

	.loading-icon {
		position: absolute;
		right: 8px;
		font-size: 11px;
	}
}
</style>
