import methods from './mixin/methods.js';
import computed from './mixin/computed.js';
import filters from './mixin/filters.js';
import directives from './mixin/directives.js';
import watch from './mixin/watch.js';

export default {
	data() {
		return {};
	},
	methods,
	computed,
	filters,
	directives,
	watch
};
