export default {
	getWinWidth() {
		return document.documentElement.clientWidth;
	},
	escapeRegex(str, delimiter) {
		let regex = new RegExp('[.\\\\+*?\\[\\^\\]$(){}=!<>|:\\' + (delimiter || '') + '-]', 'g');
		return (str + '').replace(regex, '\\$&');
	},
	getCookie(key) {
		let parts = ('; ' + document.cookie).split('; ' + key + '=');
		if (parts.length === 2) return parts.pop().split(';').shift();
	},
	setCookie(key, val, days) {
		let expires = '';
		if (days) {
			let date = new Date();
			date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
			expires = '; expires=' + date.toUTCString();
		}
		document.cookie = key + '=' + (val || '') + expires + '; path=/';
	}
}