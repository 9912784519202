<template>
	<form class="converter-form">
		<table v-if="items && items.length">
			<tr v-for="item in items" v-if="isObject(item)">
				<td>
					<label>{{ item.label }}:</label>
				</td>
				<td>
					<s-text-field
						v-if="item.isQuery"
						v-model="app.query[item.key]"
						:ref="item.ref"
						:type="item.type"
						:name="item.name"
						:placeholder="item.placeholder"
						:prepend-icon="item.icon"
						:error="item.error"
						:disabled="item.disabled"
						:loading="item.loading"
						@change="onChange(item)"
					></s-text-field>
					<s-text-field
						v-else
						v-model="$parent[item.key]"
						:ref="item.ref"
						:type="item.type"
						:name="item.name"
						:placeholder="item.placeholder"
						:prepend-icon="item.icon"
						:error="item.error"
						:disabled="item.disabled"
						:loading="item.loading"
						@input="onInput(item)"
						@change="onChange(item)"
					></s-text-field>
				</td>
			</tr>
			<tr>
				<td colspan="2" class="text-center">
					<s-btn icon="remove" @click="reset()">{{ ii('RESET') }}</s-btn>
				</td>
			</tr>
		</table>
	</form>
</template>

<style lang="less">
	.converter-form {
		table {
			width: 100%;
			border-spacing: 5px 15px;

			.refresh.s-btn button i {
				margin: 0;
			}
		}
	}
</style>

<script>
	export default {
		props: ['items'],
		methods: {
			reset() {
				this.items.forEach(item => {
					if (!this.isObject(item)) return;
					if (item.isQuery) {
						this.$set(this.app.query, item.key, '');
						this.refreshQuery();
					} else {
						this.$set(this.$parent, item.key, '');
					}

					let func = item.onChange || item.onInput || '';
					if (typeof func === 'function') func();
				});
			},
			onChange(item) {
				if (item.isQuery) {
					this.refreshQuery();
				} else {
					this.$set(this.$parent, item.key, this.$parent[item.key]);
				}

				if (item.onChange && typeof item.onChange === 'function') item.onChange();
			},
			onInput(item) {
				this.$set(this.$parent, item.key, this.$parent[item.key]);
				if (item.onInput && typeof item.onInput === 'function') item.onInput();
			}
		}
	}
</script>