<template>
	<div class="text-field" :class="classMap">
		<div v-if="error" class="error-msg">{{ error }}</div>
		<div v-if="prependIcon" class="prepend-icon" @click="focus">
			<i :class="'fa fa-fw fa-' + prependIcon"></i>
		</div>
		<input
			ref="tf"
			:type="type"
			:value="value"
			:placeholder="placeholder"
			:name="name"
			:disabled="disabled"
			@keyup.enter="$emit('enter')"
			@focus="onFocus"
			@blur="onBlur"
			@input="$emit('input', $event.target.value)"
			@change="$emit('change', $event)"
		/>
		<i v-if="error" class="error-icon fa fa-exclamation-triangle"></i>
		<i v-if="loading" class="loading-icon fa fa-spinner spin-fast"></i>
	</div>
</template>

<script>
export default {
	props: [
		'value',
		'type',
		'prepend-icon',
		'placeholder',
		'name',
		'disabled',
		'error',
		'loading',
		'autofocus'
	],
	data() {
		return {
			focused: false
		}
	},
	computed: {
		inputType() {
			return this.type || 'text';
		},
		classMap() {
			return {
				'text-field-left-icon': this.prependIcon,
				'text-field-focused': this.focused,
				'text-field-disabled': this.disabled,
				'text-field-error': this.error,
				'text-field-loading': this.loading
			};
		}
	},
	methods: {
		onFocus(e) {
			this.focused = true;
			this.$emit('focus', e);
		},
		onBlur(e) {
			this.focused = false;
			this.$emit('blur', e);
		},
		focus() {
			this.$refs.tf.focus();
		}
	},
	mounted() {
		if (this.autofocus) {
			this.focus();
		}
	}
}
</script>

<style lang="less">
.text-field {
	@tfHeight: 30px;
	@padding: 8px;

	display: inline-block;
	position: relative;
	width: 100%;
	border: 1px solid #aaa;
	background: #fff;

	&.text-field-focused {

	}

	&.text-field-disabled input {
		background: #f3f3f3;
	}

	&.text-field-error {
		border: 1px solid rgba(255, 0, 0, 0.4);
	}

	input {
		padding-left: @padding;
		padding-right: @padding;
		width: 100%;
		height: @tfHeight;
		line-height: @tfHeight;
		font-size: 13px;
		border: none;
		outline: none;
		background: transparent;
		font-family: inherit;
	}

	&.text-field-loading {
		input {
			padding-right: 18px + @padding;
		}
	}

	&.text-field-left-icon input {
		padding-left: @tfHeight + @padding;
	}

	.prepend-icon {
		position: absolute;
		left: 0;
		border-right: 1px solid #bfcbd9;
		width: @tfHeight;
		line-height: @tfHeight;
		text-align: center;
		font-size: 13px;
	}

	.error-msg {
		position: absolute;
		top: -17px;
		right: 0;
		color: #f00;
		z-index: 1;
		font-size: 13px;
	}

	.loading-icon, .error-icon {
		position: absolute;
		right: 8px;
		line-height: @tfHeight;
		font-size: 11px;

		&.error-icon {
			color: #f00;
		}
	}
}
</style>
