<template>
	<div class="developer-area">
		<h2>Webmaster area</h2>
		<div class="btn-box">
			<s-btn icon="line-chart" @click="onImproveClick">Improve your SEO rankings</s-btn>
		</div>
	</div>
</template>

<script>
export default {
	methods: {
		async onImproveClick() {
			let site = await this.input({
				type: 'text',
				title: 'Please enter your website',
				placeholder: 'example.com',
				tfIcon: 'globe',
				cancelVal: null
			});
			if (site === null) return;

			let domain = this.getDomainFromUrl(site, true);
			if (!domain) return this.alert('Please enter a valid website');

			let url = 'https://semalt.com/?s=' + this.urlEnc(domain) + '&ref=tools_' + this.$route.name;
			location.href = url;
		}
	}
}
</script>

<style lang="less">
.developer-area {
	margin: 50px 0 50px;

	.btn-box {
		padding: 20px 0;
		text-align: center;
	}
}
</style>
