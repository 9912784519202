import Vue from 'vue';
import TheHeader from "./components/TheHeader";
import TheSidebar from "./components/TheSidebar";

import AppLink from "./components/AppLink";
import SPopup from "./components/SPopup";
import SBtn from "./components/SBtn";
import STextField from "./components/STextField";
import STextArea from "./components/STextArea";
import SDropPanel from "./components/SDropPanel";
import SCopyBtn from "./components/SCopyBtn";
import STblSort from "./components/STblSort";
import SFlag from './components/SFlag.vue';
import SMultiSel from './components/SMultiSel.vue';

import QuizBox from "./components/QuizBox";
import AuthForm from "./components/AuthForm";
import FeedbackForm from "./components/FeedbackForm";
import WebmasterArea from "./components/WebmasterArea";
import ConverterForm from "./components/ConverterForm";

import utils from './mixin/utils';
import vars from './mixin/vars';

import moment from 'moment';

import 'font-awesome/css/font-awesome.min.css';
import 'material-design-iconic-font/dist/css/material-design-iconic-font.min.css';

Vue.component('the-header', TheHeader);
Vue.component('the-sidebar', TheSidebar);

Vue.component('app-link', AppLink);
Vue.component('s-popup', SPopup);
Vue.component('s-btn', SBtn);
Vue.component('s-text-field', STextField);
Vue.component('s-text-area', STextArea);
Vue.component('s-drop-panel', SDropPanel);
Vue.component('s-copy-btn', SCopyBtn);
Vue.component('s-tbl-sort', STblSort);
Vue.component('s-flag', SFlag);
Vue.component('s-multi-sel', SMultiSel);

Vue.component('quiz-box', QuizBox);
Vue.component('auth-form', AuthForm);
Vue.component('feedback-form', FeedbackForm);
Vue.component('webmaster-area', WebmasterArea);
Vue.component('converter-form', ConverterForm);

export default {
	name: 'App',
	components: {AuthForm},
	data: () => ({
		win: {
			width: utils.getWinWidth(),
			...vars.win
		},

		query: {},
		params: {},
		hash: null,
		routeName: null,

		user: undefined,
		sidebarShown: (utils.getWinWidth() >= vars.win.md),
		dialogShown: false,
		dialogData: {},
		pages: [],

		authFormShown: false,
		authFormType: 'login',
		authFormHeadersMap: {
			login: 'TO_LOGIN',
			register: 'TO_REGISTER',
			forgot: 'RESTORE_ACCOUNT',
		},
		dialog: false,
		drawer: null,

		theme: '',
		themes: [
			{key: 'ascetic', text: 'Ascetic'},
			{key: 'supersemalt', text: 'SuperSemalt'}
		],
		defaultTheme: 'supersemalt',

		languages: null,
		langLoading: false,
		loadedLangs: [],

		onModalClose: null,

		pageTextsCache: {},
		pagesDescCache: {},

		backButtonFns: [],

		agreedCookie: null,

		shouldShowQuiz: false
	}),
	props: {
		source: String
	},
	computed: {
		idu() {
			return this.user ? this.user.id : 0;
		},
		isMob() {
			return this.win.width < this.win.md;
		},
		page() {
			return this.$route.path.split('/')[2];
		},
		lang() {
			return this.$route.params.lang;
		},
		texts() {
			let key = this.lang + '_' + this.page;
			return this.pageTextsCache[key] || {};
		},
		menuItems() {
			if (!this.pages) return [];

			let items = this.pages.map(p => {
				return {
					text: p.label,
					url: '/' + p.page,
					icon: p.fa
				};
			});

			if (this.user) {
				items.push(null);
				items.push({text: 'Settings', url: '/settings', icon: 'cog'});
			}

			return items;
		}
	},
	methods: {
		resolveDialog(val) {
			if (val === undefined) {
				val = this.dialogData.cancelVal;
			}
			this.dialogData.resolve(val);
			this.dialogShown = false;
		},
		showAuthPopup() {
			this.authFormType = 'login';
			this.authFormShown = true;
		},
		async refreshUser() {
			this.user = await this.get('Auth.getMe');
		},
		async logout() {
			await this.post('Auth.logout');
			this.refreshUser();
		},
		async onAuth() {
			this.refreshUser();
			this.authFormShown = false;
		},
		async loadPages() {
			this.pages = await this.get('Page.getPages');
		},
		toggleSidebar(source) {
			this.sidebarShown = !this.sidebarShown;

			if (!this.isMob) return;

			if (this.sidebarShown) {
				this.backButtonPush(() => {
					this.sidebarShown = false;
				});
			} else {
				this.backButtonPop();
			}
		},
		async loadAvaliableLanguages() {
			this.languages = await this.get('I18n.getLanguages');
		},
		getLangGeo() {
			if (!this.languages) return null;
			let lang = this.languages.find(l => l.key === this.$route.params.lang);
			return lang && lang.geo;
		},
		getLangUrl(lang) {
			let path = this.$route.path.replace(/^\/..(\/|$)/, `/${lang}$1`);

			let query = {...this.$route.query};
			return {path, query};
		},
		chooseLang(lang) {
			utils.setCookie('lang', lang, 365);
		},
		async handleLocaleMessages() {
			let lang = this.$route.params.lang;
			if (!lang) return;

			let langs = [];
			if (this.loadedLangs.indexOf(lang) === -1) {
				langs.push(lang);
			}
			if (lang !== 'en' && this.loadedLangs.indexOf('en') === -1) {
				langs.push('en');
			}

			if (langs.length) {
				this.langLoading = true;
				let res = await this.get('I18n.getMessages', langs);
				for (let key in res) {
					this.$i18n.setLocaleMessage(key, res[key]);
					this.loadedLangs.push(key);
				}
				this.langLoading = false;
			}

			this.$i18n.locale = lang;
		},
		getThemeText() {
			let theme = this.themes.find(t => t.key === this.theme);
			return theme && theme.text;
		},
		initTheme() {
			let theme = this.getLocal('theme') || this.defaultTheme;
			if (theme) {
				this.setTheme(theme);
			}
		},
		setTheme(theme) {
			this.theme = theme;
			document.body.setAttribute('data-theme', theme);
			this.setLocal('theme', theme);
		},
		initWin() {
			window.addEventListener('resize', () => {
				let width = utils.getWinWidth();
				if (this.win.width >= this.win.md && width < this.win.md) {
					this.sidebarShown = false;
				} else if (this.win.width < this.win.md && width >= this.win.md) {
					this.sidebarShown = true;
				}
				this.win.width = width;
			});
		},
		agreeCookie() {
			this.agreedCookie = true;
			this.setLocal('agreeCookie', 1);
		},
		backButtonPush(fn) {
			let frame = document.querySelector('#history-frame');
			frame.contentWindow.postMessage({semaltPushState: true}, location.origin);
			this.backButtonFns.push(fn);
		},
		backButtonPop() {
			let fns = this.backButtonFns;
			fns[fns.length - 1] = null;

			history.back();
		},
		initHistoryFrame() {
			window.addEventListener('message', e => {
				if (e.data.semaltPopState && this.backButtonFns && this.backButtonFns.length) {
					let fn = this.backButtonFns.pop();
					fn && fn();
				}
			});
		},
		async loadTexts() {
			if (!this.lang) return;

			let key = this.lang + '_' + this.page;
			let texts = this.pageTextsCache[key];
			if (!texts) {
				texts = await this.get('Page.getPageTexts', this.page, this.lang);
				this.$set(this.pageTextsCache, key, texts);

				if (!texts) {
					texts = {};
				}
			}

			document.title = texts.title || 'Semalt Tools';
			document.querySelector('meta[name="description"]').setAttribute('content', texts.description);
		},
		initAB() {
			if (!window.statInside) return;

			let ab = {
				name: 'bg_test',
				variants: {
					normal: 1,
					white: 1
				}
			};
			statInside('ab', ab, variant => {
				if (variant === 'white') {
					document.body.style.background = '#fff';
				}
			});
		}
	},
	watch: {
		$route() {
			this.query = { ...this.$route.query };
			this.params = { ...this.$route.params };
			this.hash = this.$route.hash;
			this.routeName = this.$route.name;

			if (this.sidebarShown && this.isMob) {
				this.sidebarShown = false;
			}

			if (this.app.query.quiz) {
				this.shouldShowQuiz = true;
			}
		},
		'$route.params.lang'() {
			this.handleLocaleMessages();
		},
		async page() {
			this.loadTexts();
		},
		lang() {
			let dir = (['ar', 'he', 'ur', 'fa'].indexOf(this.lang) === -1 ? 'ltr' : 'rtl');

			document.documentElement.setAttribute('lang', this.lang);
			document.documentElement.setAttribute('dir', dir);

			let locale = this.lang;
			if (locale === 'zh') {
				locale = 'zh-CN';
			}
			moment.locale(locale);

			this.loadTexts();
		}
	},
	created() {
		this.loadAvaliableLanguages();
		if (this.$route.params.lang) {
			this.handleLocaleMessages();
		}
		this.initTheme();
		this.initWin();
		this.refreshUser();

		this.agreedCookie = !!localStorage.getItem('agreeCookie');
	},
	async mounted() {
		//this.initAB();
		await this.loadPages();
		this.initHistoryFrame();
	}
};
