import axios from 'axios';
import marked from 'marked';

export default {
	refreshQuery(opts = {}) {
		let query = this.app.query;
		for (let i in query) {
			if (!query.hasOwnProperty(i)) continue;
			if (query[i] === '' || query[i] == null) {
				delete query[i];
			}
		}

		let hash = opts.keepHash ? this.app.hash : null;

		this.navigate({ query, hash });
	},
	navigate(opts, isReplace = false) {
		let query = opts.query || {};

		let method = isReplace ? 'replace' : 'push';
		this.$router[method]({ ...opts, query }).catch(err => {
			if (err.name !== 'NavigationDuplicated') {
				throw err;
			}
		});
	},
	editQueryParams(params, isReplace = false) {
		let query = {...this.app.query, ...params};
		this.navigate({query}, isReplace);
	},
	async get(classMethod, ...args) {
		let argsJson = JSON.stringify(args);
		let params = { argsJson };
		let res = await axios.get('/api/call/' + classMethod, { params });
		if (res.data.err) {
			throw new Error(res.data.err);
		}
		return res.data.res;
	},
	async post(classMethod, ...args) {
		let data = { args };
		let res = await axios.post('/api/call/' + classMethod, data);
		if (res.data.err) {
			throw new Error(res.data.err);
		}
		return res.data.res;
	},
	input(opts) {
		return new Promise(resolve => {
			this.app.dialogData = opts;
			this.app.dialogData.resolve = resolve;
			this.app.dialogShown = true;
		});
	},
	async alert(text = '', title = null) {
		let type = 'alert';
		let cancelVal = null;
		if (title === null) {
			title = this.ii('MESSAGE');
		}
		return this.input({type, text, title, cancelVal});
	},
	async confirm(text = '', title = null) {
		let type = 'confirm';
		let cancelVal = false;
		if (title === null) {
			title = this.ii('CONFIRMATION');
		}
		return this.input({type, text, title, cancelVal});
	},
	async prompt(text = '', val = '', title = null) {
		let type = 'text';
		let cancelVal = null;
		if (title === null) {
			title = this.ii('INPUT');
		}
		return this.input({type, text, title, val, cancelVal});
	},
	copy(text) {
		if (navigator.clipboard && navigator.clipboard.writeText) {
			return navigator.clipboard.writeText(text)
		}
		let tf = document.createElement('textarea');
		tf.style.position = 'fixed';
		tf.style.top = '-1000px';
		tf.style.opacity = 0;
		tf.value = text;
		document.body.appendChild(tf);

		tf.focus();
		tf.select();
		document.execCommand('copy');

		return Promise.resolve();
	},
	hit(event, data) {
		if (!window.CleverJump) return;

		CleverJump.hit(event, data);
	},
	getDomainFromUrl(url, keepWww = false) {
		let domain = String(url).trim().toLowerCase().replace(/^https?:\/\//i, '').replace(/\/.*/, '');
		if (!keepWww) {
			domain = domain.replace(/^www\./i, '');
		}
		return domain;
	},
	urlEnc(raw) {
		return encodeURIComponent(raw);
	},
	urlDec(raw) {
		return decodeURIComponent(raw);
	},
	setLocal(key, val) {
		localStorage.setItem(key, val);
	},
	getLocal(key) {
		return localStorage.getItem(key);
	},
	parseJson(json, showError = false) {
		try {
			return JSON.parse(json);
		} catch (err) {
			if (showError) console.log(err);
			return null;
		}
	},
	toJson(data, opts = {}) {
		if (opts.pretty) return JSON.stringify(data, '', 4);
		return JSON.stringify(data);
	},
	beautifyJson(json) {
		return this.toJson(this.parseJson(json), {pretty: true});
	},
	isObject(obj) {
		return typeof obj === 'object';
	},
	isObjectEmpty(obj) {
		if (!obj) return true;
		for (let i in obj) return false;
		return true;
	},
	ii(origKey) {
		if (!this.app.loadedLangs.length || !origKey) return '';
		let key = origKey.toUpperCase().replace(/[\s-]/g, '_').replace(/[()]/g, '');
		return this.$i18n.t(key);
	},
	markdownToHtml(md) {
		if (!md) return '';
		return marked(md);
	},
	sleep(timeout) {
		return new Promise(resolve => {
			setTimeout(resolve, timeout);
		});
	},
	escapeHtml(html) {
		if (!html) return '';
		return html
		.replace(/&/g, '&amp;')
		.replace(/</g, '&lt;')
		.replace(/>/g, '&gt;')
		.replace(/"/g, '&quot;')
		.replace(/'/g, '&#039;');
	},
	unescapeHtml(html) {
		if (!html) return '';
		return html
		.replace(/&amp;/g, '&')
		.replace(/&lt;/g, '<')
		.replace(/&gt;/g, '>')
		.replace(/&quot;/g, '"')
		.replace(/&#039;/g, "'");
	},
	wrapLinks(html) {
		if (!html) return html;

		html = this.escapeHtml(html);
		html = html.replace(/(https?:\/\/[^\s"'(),]+)[^\.\s,]/gi, '<a href="$&" target="_blank" rel="noopener noreferrer">$&</a>');
		return html;
	},
	newLineToBr(raw, maxNewLines = null) {
		let str = String(raw || '').replace(/\r?\n/g, '<br>');

		maxNewLines = parseInt(maxNewLines);
		if (maxNewLines) {
			let re = new RegExp('((<br>){' + maxNewLines + '})(<br>)+');
			str = str.replace(re, '$1');
		}
		return str;
	},
};
