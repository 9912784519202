<template>
	<span>
		<img
			src="../assets/pixel.png"
			v-if="cc"
			class="famfamfam-flags"
			:class="countryCode"
			:alt="countryCode + ' flag'"
		/>
		<span v-else class="empty-flag"></span>
	</span>
</template>

<style scoped>
span {
	display: inline-block;
}
img {
	display: inline-block;
	vertical-align: middle;
	background-position: 100px;
}
</style>

<script>
import 'famfamfam-flags/dist/sprite/famfamfam-flags.min.css';

export default {
	props: ['cc', 'no-title'],
	computed: {
		countryCode() {
			return String(this.cc).toLowerCase();
		}
	}
}
</script>
