import Vue from 'vue';
import App from './App.vue';
import mixin from './mixin';
import router from './router';
import i18n from './i18n'

Vue.config.productionTip = false;

Vue.mixin(mixin);

window.app = new Vue({
	router,
	i18n,
	render: h => h(App)
}).$mount('#app');
