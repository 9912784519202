<template>
	<div class="quiz-box">
		<template v-if="stage === 0">
			<div class="quiz-title">Do you have a website?</div>
			<div class="quiz-variant">
				<s-btn icon="check" color="grey" @click="answer(true)">Yes</s-btn>
			</div>
			<div class="quiz-variant">
				<s-btn icon="remove" color="grey" @click="answer(false)">No</s-btn>
			</div>
		</template>
		<template v-else>
			<div class="quiz-title">Enter your website to improve its Google rankings</div>
			<div class="quiz-variant">
				<s-text-field
					v-model="website"
					prepend-icon="globe"
					placeholder="http://"
					@enter="onEnterWebsite"
				></s-text-field>
			</div>
		</template>
	</div>
</template>

<style lang="less">
.quiz-box {
	position: fixed;
	right: 10px;
	bottom: 10px;
	padding: 15px;
	border: 1px solid #000;
	background: #fff;
}
.quiz-title {
	font-weight: 500;
}
.quiz-variant {
	padding: 5px 0 0;

	.s-btn {
		width: 100%;
		button {
			width: 100%;
		}
	}
}
</style>

<script>
export default {
	data() {
		return {
			stage: 0,
			website: ''
		};
	},
	methods: {
		answer(has) {
			if (has) {
				this.stage++;
			} else {
				this.$emit('close', false);
			}
		},
		onEnterWebsite() {
			let domain = this.getDomainFromUrl(this.website, true);
			if (!domain) return this.alert('Please enter a valid website');

			let url = 'https://semalt.com/?s=' + this.urlEnc(domain) + '&ref=tools_quiz';
			location.href = url;
		}
	}
}
</script>