<template>
	<transition name="modal">
		<div class="popup" v-if="mustBeShown" @click="onClick">
			<div class="popup-panel" :style="style" ref="popupBox">
				<div class="popup-header" @mousedown="onHeaderMouseDown" @touchstart="onHeaderTouchStart"
					 ref="popupHeader">
					<div class="left">
						<slot name="header"></slot>
					</div>
					<div class="right">
						<span class="popup-close fa fa-close" @click="close('icon')"></span>
					</div>
				</div>
				<div class="popup-content-outer">
					<div
						ref="content"
						class="popup-content"
						:style="contentStyle"
						@scroll="$emit('scroll', $event)"
					>
						<slot>
							<span @click="shown = !shown">empty popup</span>
							<transition name="modal">
								<div v-if="shown">hello</div>
							</transition>
						</slot>
					</div>
				</div>
				<div class="popup-buttons">
					<slot name="buttons"></slot>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
export default {
	props: ['value', 'persistent'],
	data() {
		return {
			contentStyle: {},
			dndStartX: 0,
			dndStartY: 0,
			offsetLeft: 0,
			offsetTop: 0,
			shown: false
		};
	},
	computed: {
		mustBeShown() {
			if (this.value === undefined) return true;
			return !!this.value;
		},
		style() {
			let style = {
				left: this.offsetLeft + 'px',
				top: this.offsetTop + 'px'
			};
			return style;
		}
	},
	methods: {
		onClick(e) {
			if (this.persistent) return;
			if (e.target !== this.$el) return;
			this.close('outside');
		},
		close(type) {
			if (type !== 'back') {
				this.app.backButtonPop();
			}

			this.$emit('input', false);
			this.$emit('close');
		},

		onHeaderTouchStart(e) {
			if (e.target !== e.currentTarget) return;
			if (!e.touches || !e.touches[0]) return;

			e.preventDefault();

			let touch = e.touches[0];

			this.dndStartY = touch.clientY - this.offsetTop;

			document.addEventListener('touchmove', this.onDocTouchMove);
			document.addEventListener('touchend', this.unbindDocTouchEnd);

			this.unbindDocMouseUp();
		},
		onDocTouchMove(e) {
			if (!e.touches || !e.touches[0]) return;

			let touch = e.touches[0];
			this.offsetTop = touch.clientY - this.dndStartY;
		},
		unbindDocTouchEnd(e) {
			document.removeEventListener('touchmove', this.onDocTouchMove);
			document.removeEventListener('touchend', this.unbindDocTouchEnd);
		},

		onHeaderMouseDown(e) {
			if (e.target !== e.currentTarget) return;

			e.preventDefault();

			this.dndStartX = e.clientX - this.offsetLeft;
			this.dndStartY = e.clientY - this.offsetTop;

			document.addEventListener('mousemove', this.onDocMouseMove);
			document.addEventListener('mouseup', this.unbindDocMouseUp);

			this.unbindDocTouchEnd();
		},
		onDocMouseMove(e) {
			this.offsetLeft = e.clientX - this.dndStartX;
			this.offsetTop = e.clientY - this.dndStartY;
		},
		unbindDocMouseUp(e) {
			document.removeEventListener('mousemove', this.onDocMouseMove);
			document.removeEventListener('mouseup', this.unbindDocMouseUp);
		}
	},
	watch: {
		value() {
			if (this.value) {
				this.offsetLeft = 0;
				this.offsetTop = 0;
			}
		},
		mustBeShown() {
			if (!this.mustBeShown) return;

			this.app.backButtonPush(() => this.close('back'));
		}
	}
}
</script>

<style lang="less">
@import "../styles/vars";

.popup {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	padding: 5px 0 0;
	background: rgba(250, 250, 250, 0.7);
	z-index: 999999;

	@media (min-height: 470px) {
		padding: 25px 0 0;
		&.modal-popup {
			padding: 35px 0 0;
		}
	}

	@media (min-height: 600px) {
		padding: 50px 0 0;
		&.modal-popup {
			padding: 60px 0 0;
		}
	}

	.popup-panel {
		position: relative;
		margin: 0 auto;
		width: 400px;
		max-width: 98%;
		font-size: 14px;
		border: 1px solid #000;
		background: #fff;

		.popup-header {
			position: relative;
			padding: 0 9px 0 9px;
			height: 30px;
			line-height: 30px;
			font-weight: 500;
			font-size: 13px;
			text-transform: uppercase;
			color: #fff;
			background: @primColor;

			.popup-close {
				position: relative;
				top: -1px;
				color: #fff;
				opacity: 0.8;
				cursor: pointer;

				&:hover {
					opacity: 1;
					text-decoration: none;
				}
			}
		}
		.popup-content-outer {
			width: 100%;
			height: 100%;
		}
	}
	.overlaying-fix {
		position: fixed;
		left: 0;
		top: 0;
		width: 100vw;
		height: 100vh;
		cursor: nwse-resize;
		z-index: 1999999;
	}
	.popup-content {
		padding: 15px;
		max-height: 100%;

		&.popup-scrollable {
			overflow-y: scroll;
		}
	}
	.popup-buttons {
		text-align: center;

		& > * {
			padding: 0 0 15px;
		}

		.s-btn {
			margin: 0 3px;
		}
	}

	&.dialog-popup .popup-buttons button {
		min-width: 80px;
	}
}
</style>
