<template>
	<div class="auth-3party">
		{{ ii('LOGIN_WITH') }}
		<!--<span class="link" @click="loginOAuth('github')">
			<i class="fa fa-fw fa-github github-color"></i>
			<span>GitHub</span>
		</span>-->
		<span class="link" @click="loginOAuth('facebook')">
			<i class="fa fa-fw fa-facebook-official facebook-color"></i>
			<span class="gh-color">Facebook</span>
		</span>
		<span class="link" @click="loginOAuth('google')">
			<i class="fa fa-fw fa-google google-color"></i>
			<span>Google</span>
		</span>
	</div>
</template>

<script>
	export default {
		methods: {
			loginOAuth(provider) {
				let url = '/api/auth/' + provider;
				let width = 700;
				let height = 500;

				if (provider === 'facebook') {
					width = 600;
					height = 500;
				} else if (provider === 'github') {
					height = 600;
				}

				let l = window.screenX + (window.outerWidth - width) / 2;
				let t = window.screenY + (window.outerHeight - height) / 2;
				let winProps = 'width='+width+',height='+height+',left='+l+',top='+t+',status=no,resizable=yes,toolbar=no,menubar=no,scrollbars=yes';
				let win = window.open(url, provider, winProps);

				let checkClosed = () => {
					if (!win.closed) return setTimeout(checkClosed, 100);

					this.$emit('close', {type: provider});
				};

				checkClosed();
			}
		}
	}
</script>
