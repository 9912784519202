<template>
	<div class="tool-feedback">
		<h2>We appreciate your feedback</h2>
		<form class="feedback-form">
			<table>
				<tr>
					<td>
						<s-text-field
							v-model="name"
							prepend-icon="user-circle"
							placeholder="Your name"
							:error="nameError"
						></s-text-field>
					</td>
					<td>
						<s-text-field
							v-model="email"
							prepend-icon="envelope-o"
							placeholder="E-mail"
							:error="emailError"
						></s-text-field>
					</td>
				</tr>
				<tr>
					<td colspan="2">
						<s-text-area
							v-model="text"
							placeholder="Your feedback"
							:error="textError"
						></s-text-area>
					</td>
				</tr>
				<tr>
					<td colspan="2" class="text-center">
						<s-btn icon="send" :loading="isSending" @click="send">Send</s-btn>
					</td>
				</tr>
			</table>
		</form>
	</div>
</template>

<script>
export default {
	data() {
		return {
			email: '',
			name: '',
			text: '',

			emailError: null,
			nameError: null,
			textError: null,

			isSending: false
		};
	},
	methods: {
		async send() {
			if (!this.text || !this.text.trim()) {
				this.textError = 'Feedback is required field';
				return;
			}

			this.emailError = this.nameError = this.textError = null;

			this.isSending = true;
			let data = {
				tool: this.$route.name,
				email: this.email,
				name: this.name,
				text: this.text
			};
			let res = await this.post('Feedback.send', data);
			this.isSending = false;

			this.text = '';
		}
	}
}
</script>

<style lang="less">
.tool-feedback {
	.feedback-form {
		max-width: 500px;
		margin: 0 auto;

		table {
			width: 100%;

			td {
				padding: 5px;
			}
		}

		textarea {
			width: 100%;
			height: 150px;
		}
	}
}
</style>
