<template>
	<span class="s-btn">
		<button
			:type="btnType"
			:style="styleMap"
			:class="getClasses()"
			:disabled="disabled || loading"
			@click="onClick"
		>
			<i v-if="icon" :class="iconClass"></i>
			<slot></slot>
		</button>
	</span>
</template>

<script>
export default {
	props: ['type', 'hidden', 'disabled', 'loading', 'icon', 'min-width', 'color'],
	data() {
		return {
			title: null,
			filesCnt: 0,
			fileAvailable: true
		};
	},
	computed: {
		btnType() {
			return this.type || 'button';
		},
		iconClass() {
			if (this.loading) return 'fa fa-fw fa-spinner spin-fast';
			return 'fa fa-fw fa-' + this.icon;
		},
		styleMap() {
			let map = {};
			if (this.minWidth !== undefined) {
				let w = this.minWidth;
				if (!isNaN(w)) {
					w += 'px';
				}
				map.minWidth = w;
			}
			return map;
		}
	},
	methods: {
		getClasses() {
			let COLORS = ['primary', 'orange', 'red', 'transparent', 'soft-red', 'grey'];
			let SIZES = ['normal', 'small'];

			let color = COLORS.indexOf(this.color) !== -1 ? this.color : COLORS[0];
			let size = SIZES.indexOf(this.size) !== -1 ? this.size : SIZES[0];

			let classes = ['btn', 'btn-' + color, 'btn-' + size, 'nowrap'];
			if (this.hidden) {
				classes.push('btn-hidden');
			}

			return classes.join(' ');
		},
		onClick(e) {
			this.$emit('click', e);
		},
		onFileChanged(e) {
			if (!e.target.files || !e.target.files.length) {
				this.title = null;
				this.filesCnt = 0;
				return;
			}

			this.title = [...e.target.files].map(file => file.name).join('\n');
			this.filesCnt = e.target.files.length;

			this.$emit('change', e);
		},
		resetFile() {
			this.title = null;
			this.filesCnt = 0;

			this.fileAvailable = false;
			this.$nextTick(() => {
				this.fileAvailable = true;
			});
		}
	}
}
</script>

<style lang="less">
.s-btn {
	display: inline-block;

	button {
		&:not([disabled]) {
			&:hover {

			}
			&:active {

			}
		}

		&[disabled] {

		}

		i {
			margin-right: 3px;
		}
	}
}
</style>
