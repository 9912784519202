export default {
	focus: {
		inserted(el, binding, vnode) {
			if (binding.value && binding.value.minWidth) {
				if (vnode.context.win.width < binding.value.minWidth) return;
			}
			el.focus();
		}
	},
	'block-scroll': {
		bind(el, binding, vNode) {
			let handler = e => {
				e.stopPropagation();

				if (el.scrollHeight <= el.offsetHeight) return;

				if (
					e.deltaY < 0 && el.scrollTop <= 0 ||
					e.deltaY > 0 && el.scrollTop + el.offsetHeight >= el.scrollHeight
				) {
					e.preventDefault();
				}
			};

			el.__vueMousewheelOutside__ = handler;

			el.addEventListener('wheel', handler);
		},
		unbind(el) {
			el.addEventListener('wheel', el.__vueMousewheelOutside__);
		}
	},
	'mousedown-outside': {
		bind(el, binding, vNode) {
			if (typeof binding.value !== 'function') {
				const compName = vNode.context.name;
				let warn = `[Vue-mousedown-outside:] provided expression '${binding.expression}' is not a function, but has to be`;
				if (compName) {
					warn += `Found in component '${compName}'`;
				}

				console.warn(warn);
			}

			const bubble = binding.modifiers.bubble;
			const handler = (e) => {
				if (bubble || (!el.contains(e.target) && el !== e.target)) {
					binding.value(e);
				}
			};
			el.__vueMousedownOutside__ = handler;

			document.addEventListener('mousedown', handler);
		},

		unbind(el, binding) {
			document.removeEventListener('mousedown', el.__vueMousedownOutside__);
			el.__vueMousedownOutside__ = null;
		}
	}
};
